import './style.css'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import * as dat from 'lil-gui'
import {MeshBasicMaterial} from 'three'
import gsap from 'gsap'

/**
 * BASE
 */
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load("./textures/matcaps/7.png")
const matcapTexture2 = textureLoader.load("./textures/matcaps/8.png")
const parameters = {
	cameraRotation: 0.2,
	cameraDistance: 3,
	cameraX : -1,
	cameraY : 1.5,
	cameraZ : 3,
}
// let text

/**
 * SOL
 */
const groundGeomery = new THREE.PlaneGeometry(100, 100, 1, 1)
const groundMaterial = new THREE.MeshStandardMaterial({color: 0xbbbbbb})
var ground = new THREE.Mesh(groundGeomery, groundMaterial)
ground.receiveShadow = true
ground.rotation.x = Math.PI * -0.5
scene.add(ground)

/**
 * SPOT LUMIERE
 */
const spotLight = new THREE.SpotLight(0x007517, 4);
spotLight.position.set(1, 2, 2);

spotLight.angle = 0;
spotLight.penumbra = 0.7;
spotLight.decay = 2;
spotLight.castShadow = true;
spotLight.distance = 15;
spotLight.shadow.mapSize.width = 2048;
spotLight.shadow.mapSize.height = 2048;
spotLight.shadow.camera.near = 1;
spotLight.shadow.camera.far = 8;
scene.add(spotLight)
spotLight.shadow.camera.fov = 30


/**
 * HELPER LUMIERE
 */
const lightHelper = new THREE.SpotLightHelper(spotLight);
lightHelper.visible = false
scene.add(lightHelper);

/**
 * MOUVEMENT SPOT
 */
const cursor = {
	x: 0,
	y: 0
}
window.addEventListener("mousemove", e => {
	cursor.x = e.clientX / sizes.width - 0.5
	cursor.y = -(e.clientY / sizes.height - 0.5)
})

const letterMeshes = [];

const textMaterial = new THREE.MeshStandardMaterial(
	{
		color: 0x8ccc7f,
		roughness: 0.7,
		metalness: 0.7,
	})
/**
 * TEXTE HUSK
 */
const fontLoader = new FontLoader();
fontLoader.load(
	"./fonts/helvetiker_bold.typeface.json",
	(font) => {
		const letters = [
			{
				letter: "H",
				x: -0.59,
				delay: 1
			},
			{
				letter: "U",
				x: -0.12,
				delay: 1.2
			},
			{
				letter: "S",
				x: 0.33,
				delay: 1.4
			},
			{
				letter: "K",
				x: 0.79,
				delay: 1.6
			},
		]
		letters.forEach(item => {

			const textGeometry = new TextGeometry(item.letter, {
				font: font,
				size: 0.55,
				height: 0.01,
				curveSegments: 10,
				bevelThickness: 0.2,
				bevelSize: 0,
				bevelSegments: 10,
				bevelEnabled: true

			})
			const text = new THREE.Mesh(textGeometry, textMaterial);
			letterMeshes.push(text)
			text.geometry.computeBoundingBox()
			text.castShadow = true
			const boundingBox = text.geometry.boundingBox;
			textGeometry.translate(
				item.x,
				0.01,
				-(boundingBox.max.z - boundingBox.min.z) * 0.5)
			scene.add(text)

			// gsap.from(text.position,{
			//     y: 2,
			//     duration: 1.5,
			//     delay: item.delay,
			//     ease: "power4.in"
			// })
		})

		// const tl = gsap.timeline()
		// tl.to(camera.position, {y: parameters.cameraY - 0.1, duration: 0.06, delay: 2.5})
		// tl.to(camera.position, {y: parameters.cameraY + 0.1, duration: 0.06})
		// tl.to(camera.position, {y: parameters.cameraY - 0.1, duration: 0.06, delay: 0.08})
		// tl.to(camera.position, {y: parameters.cameraY + 0.1, duration: 0.06})
		// tl.to(camera.position, {y: parameters.cameraY - 0.1, duration: 0.06, delay: 0.08})
		// tl.to(camera.position, {y: parameters.cameraY + 0.1, duration: 0.06})
		// tl.to(camera.position, {y: parameters.cameraY - 0.1, duration: 0.06, delay: 0.08})
		// tl.to(camera.position, {y: parameters.cameraY + 0.1, duration: 0.06})
		// tl.to(camera.position, {y: parameters.cameraY, duration: 0.06})


		if (gui) {
			const textFolder = gui.addFolder("Texte")
			textFolder.addColor(textMaterial, "color").name("Couleur")
		}

		gsap.to(spotLight, {
			angle: Math.PI / 5,
			duration: 3,
			delay: 0.5,
			ease: "power4.out"
		})
	}
)

const sizes = {
	width: window.innerWidth,
	height: window.innerHeight
}

window.addEventListener('resize', () => {
	sizes.width = window.innerWidth
	sizes.height = window.innerHeight
	camera.aspect = sizes.width / sizes.height
	camera.updateProjectionMatrix()
	renderer.setSize(sizes.width, sizes.height)
	renderer.setPixelRatio(2)
})

/**
 * CAMERA
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = parameters.cameraX
camera.position.y = parameters.cameraY
camera.position.z = parameters.cameraZ
scene.add(camera)

/**
 * CONTROLS
 */
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableZoom = false
controls.maxPolarAngle = Math.PI / 2.2
controls.enabled = false

/**
 * RENDERER
 */
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
	antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(2)
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.outputEncoding = THREE.sRGBEncoding;

/**
 * ANIMATIONS
 */
const clock = new THREE.Clock()

const tick = () => {
	const elapsedTime = clock.getElapsedTime()
	gsap.to(spotLight.position, {
		x: Math.sin(cursor.x * Math.PI),
		z: Math.cos(cursor.x * Math.PI) + 1.5
	})
	camera.lookAt(0, 0, 0)

	renderer.render(scene, camera)
	window.requestAnimationFrame(tick)
}

tick()

/**
 * DEBUG
 */
let gui
// if(localStorage.getItem("debugMode") === "true"){
gui = new dat.GUI()
gui.close()
const lightFolder = gui.addFolder("Éclairage")
lightFolder.add(lightHelper, "visible").name("Debug projecteur")
lightFolder.add(spotLight, "castShadow").name("Ombre")
lightFolder.add(spotLight, "angle").name("Taille du projecteur").min(0.01).max(Math.PI / 5)
lightFolder.add(spotLight, "penumbra").name("Pénombre").min(0).max(1)
lightFolder.add(spotLight, "power").name("Puissance").min(1).max(5)
lightFolder.addColor(spotLight, "color").name("Couleur")

const cameraFolder = gui.addFolder("Caméra")
cameraFolder.add(controls, "enabled").name("Mouvement libre")
// cameraFolder.add(parameters,"cameraRotation").name("Rotation camera").min(0).max(7)
// cameraFolder.add(parameters,"cameraDistance").name("Distance camera").min(1).max(7)
// cameraFolder.add(parameters,"moveUp")

const textFolder = gui.addFolder("Texte")
textFolder.addColor(textMaterial, "color")
textFolder.add(textMaterial, "roughness").min(0).max(1)
textFolder.add(textMaterial, "metalness").min(0).max(1)


// }

document.querySelector(".webgl").addEventListener("click", () => {

	const x = (Math.random() - 0.2) * 2
	const y = Math.abs((Math.random() - 0.1) * 2)
	const z = (Math.random() + 0.5) * 2

	gsap.to(camera.position, {
		x: x,
		y: y,
		z: z,
		duration: 1
	})
})

document.querySelector(".replay").addEventListener("click", () => {
	window.location.reload()
})
